<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm delete this profile?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      color="primary"
      dark
      to='/newHelper'
      class='mb-5'
    >New Domestic Helper
    </v-btn>
    <v-btn
      dark
      @click='exportAll'
      class='ml-4 mb-5'
    >Export All Data
    </v-btn>
    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
    <v-row>
      <v-col>
        
          <v-text-field :rules="requiredRule" v-model="searchValue" label="Search"> 
          </v-text-field>
       
      </v-col>
      <v-col>
        
        <v-select  :rules="requiredRule" item-value="value" item-title="text" v-model="searchType" label="Search Type" :items="[{
          text : 'Name', value : 'name'
        },{
          text : 'Phone Number', value : 'phoneNumber'
        },{
          text : 'Profile Id', value : 'portfolioId'
        }]">
        </v-select>
    
    </v-col>
    <v-col>
      <v-btn color='primary' @click="searchHandler">
      Search
     </v-btn>
     <v-btn class='ml-3' @click="clearSearchbar">
      Clear
     </v-btn>
    </v-col>
    </v-row>
    </v-form>
   

    <h2>Domestic Helpers</h2>


    <v-card>
      <v-card-title>
        <div class="text-center pt-2">
        <v-btn
      class="ma-5"
      fab

      small
      color="primary"
      :disabled="page === 1"
      @click="fetchPage('left')"
    >
      <v-icon dark>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    Next 150 Records
    <v-btn
     @click="fetchPage('right')"
      class="ma-5"
      fab

      small
      color="primary"
    >
      <v-icon dark>
        mdi-arrow-right
      </v-icon>
    </v-btn>

    </div>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Global Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading='loading'
        :search="search"
        hide-default-footer
        loading-text="Loading data... Please wait"
        :headers="headers"
        :items="result"
        :items-per-page="150"

      >

        <template v-slot:item.id="{ item }">
          <a @click='openLink(item.id)'>{{item.id}}</a>
        </template>

        <template v-slot:item.skills="{ item }">
          <p>{{item.careInfant >= 3 ? "Newborn" : ""}}</p>
          <p>{{item.careChild >= 3 ? "Child" : ""}}</p>
          <p>{{item.careElderly >= 3 ? "Elderly" : ""}}</p>
        </template>

        <template v-slot:item.workexps="{ item }">
          <p
            v-for='(e,i) in item.workExp'
            :key='i'
          >
            {{e.country}} : {{e.duration}}
          </p>
        </template>

        <template v-slot:item.image="{ item }">
          <img
            v-if='item.fileLocation.includes("/null")'
            src="../assets/notyet.jpg"
            width='80'
          />
          <img
            v-else
            :src=' `https://storage.googleapis.com/starjasmine-aacba.appspot.com/${item.fileLocation}`'
            width='80'
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-btn
      class="ma-5"
      fab
      dark
      small
      color="primary"
      @click="fetchPage('left')"
    >
      <v-icon dark>
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <v-btn
     @click="fetchPage('right')"
      class="ma-5"
      fab
      dark
      small
      color="primary"
    >
      <v-icon dark>
        mdi-arrow-right
      </v-icon>
    </v-btn>

    </div>
    </v-card>

  </div>
</template>


<script>
import { db } from "../firebase/firebaseConfig";
import { getAllHelpers, getNextPage,searchHelpers} from "../dataservices/helperDataservies";
import { onSnapshot } from "firebase/firestore";

export default {

  props: ['type'],


  data: () => ({
    page: 1,
    valid : true,
    requiredRule: [(v) => !!v || "This Field is required"],
    searchValue : null,
    searchType : null,
    count : 0,
    lastDocument : null,
    options: {},
    dialog2: false,
    loading: true,
    dialog: false,
    search: null,
    result: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Valid?", value: "valid" },
      { text: "Portfolio Id", value: "portfolioId" },

      { text: "Price", value: "price" },
      { text: "Image", value: "image" },
      { text: "Name", value: "name" },
      { text: "Age", value: "age" },
      { text: "Work Exp (Location : Duration)", value: "workexps" },
      { text: "Skills", value: "skills" },

      { text: "Nationality", value: "nationality" },
      { text: "Type", value: "status" },

      { text: "Availability (Date)", value: "availabilityDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Domestic helpers",
        disabled: true,
        href: "",
      },
    ],
  }),
  watch: {
    
    },
  methods: {
   async fetchPage(direction){
      if(direction === 'left'){
        if(this.page !== 1){
          this.page--;
        }
        await this.getDataFromApi(this.page)
      }else{
        this.page++ ;
          await this.getDataFromApi(this.page)
      }
    },
    clearSearchbar(){
      this.searchValue = null;
      this.searchType = null;
    },
    async searchHandler(){
      let valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      if(this.searchType === null){
        alert("Search Type cant be empty")
        return;
      }
      const data = await searchHelpers(this.searchValue,this.searchType)
      this.result = data;
    },
    async getDataFromApi (page) {
      
      this.loading = true;
      if(page > 1){
       const next =  await getNextPage(this.lastDocument);
       this.lastDocument =  next.docs[next.docs.length -1];
       this.loading = false;
        return this.result = next.docs.map((doc) => doc.data());
      }
      let query;
    if (this.type === "myHelpers") {
      query = await getAllHelpers(true);
    } 
    else if (this.type === "available"){
      query = await getAllHelpers(false,true);
    }
    else {

      query = await getAllHelpers(false);
    }
    
    onSnapshot(query, (docs) => {
      this.result = docs.docs.map((doc) => doc.data());
      this.lastDocument =  docs.docs[docs.docs.length -1];
      this.loading = false;
    });
      },
    openLink(id) {
      let routeData = this.$router.resolve({ name: `/helpers/${id}` });
      window.open(routeData.route.name, "_blank");
    },
    exportAll() {
      // Create link and download
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:application/json;charset=utf-8,%EF%BB%BF" +
          encodeURIComponent(JSON.stringify(this.result))
      );
      link.setAttribute("download", "helper");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async confirmDelete() {
      this.dialog2 = false;
      await db.collection("Helpers").doc(this.selectedDeleteItem).delete();
      this.selectedDeleteItem = null;
      alert("Profile Deleted");
    },
    async deleteItem(item) {
      this.selectedDeleteItem = item.id;
      this.dialog2 = true;
    },
  },
  async mounted() {
    await this.getDataFromApi(this.page)

  },
};
</script>
