<template>
  <v-app>
    <h1>Dashboard</h1>
    <v-container
      fluid
      text-xs-center
    >
      <v-layout wrap>
        <v-flex
          v-if='claims.role === "franchisee"'
          xs12
          sm4
          order-md2
          order-sm1
          pa-2
        >
          <v-card
            link
            :to='"/helper/all?type=myHelpers"'
            class='text-center'
            dark
            tile
            flat
            color="blue lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{helpers}}</v-card-text>
            <v-card-text class="display-1 text-center">My Helpers</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm4
          order-md2
          order-sm1
          pa-2
        >
          <v-card
            link
            :to='"/helper/all"'
            class='text-center'
            dark
            tile
            flat
            color="red lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{allHelpers}}</v-card-text>
            <v-card-text class="display-1 text-center">All Helpers</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
         
          sm4
          order-md3
          order-sm2
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/helper/all?type=available")}'
            class='text-center'
            dark
            tile
            flat
            color="green lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{avaiableHelpers}}</v-card-text>
            <v-card-text class="display-1 text-center">Available Helpers </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          v-if='claims.role !== "franchisee"'
          sm4
          order-md3
          order-sm2
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/helper/matchedresults")}'
            class='text-center'
            dark
            tile
            flat
            color="orange lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{inquries}}</v-card-text>
            <v-card-text class="display-1 text-center">Inquries</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm4
          order-md4
          order-sm3
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/helper/applications")}'
            class='text-center'
            dark
            tile
            flat
            color="green"
            hover
          >
            <v-card-text class="display-2 text-center">{{applications}}</v-card-text>
            <v-card-text class="display-1 text-center">Applications</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm4
          order-md4
          order-sm3
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/cases/all")}'
            class='text-center'
            dark
            tile
            flat
            color="purple"
            hover
          >
            <v-card-text class="display-2 text-center">{{cases}}</v-card-text>
            <v-card-text class="display-1 text-center">Cases</v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { getDashboardData } from "../dataservices/appSettingsDataservices";
import { 
  getCountFromServer  
} from 'firebase/firestore';
export default {
  name: "Home",
  async mounted() {
    const refs = await getDashboardData();
    if (this.claims.role !== "admin") {
      const docs = await refs.helperRef.get();
      this.helpers = docs.docs.length;
    }
  const [
  availableHelpersRef,
  allHelpersRef,
  registerRef,
   MatchResultsWithContactInfoRef,
  casesRef,
  
] = await Promise.all([
getCountFromServer(refs.avaiableHelperRef),
  getCountFromServer(refs.allhelperRef),
   getCountFromServer(refs.registerRef),
   getCountFromServer(refs.MatchResultsWithContactInfoRef),
   getCountFromServer(refs.casesRef),

]);
this.avaiableHelpers = availableHelpersRef.data().count;
this.allHelpers = allHelpersRef.data().count;
 this.applications = registerRef.data().count;
 this.inquries = MatchResultsWithContactInfoRef.data().count;
 this.cases = casesRef.data().count;

  },

  data() {
    return {
      avaiableHelpers : 0,
      allHelpers: 0,
      cases: 0,
      helpers: 0,
      applications: 0,
      inquries: 0,
    };
  },
  components: {},
  computed: {
    ...mapFields(["claims"]),
  },
};
</script>
