import { db, storage } from "../firebase/firebaseConfig";
import store from "../store/index";
import axios from "axios";
const { collection, query, where } = require("firebase/firestore");

const options = {
	"content-type": "text/json",
	"Access-Control-Allow-Origin": "*",
};

export const getCase = async (id) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return db.collection("cases").where("caseNumber", "==", parseInt(id));
	} else {
		return db
			.collection("cases")
			.where("bio.franchiseeId", "==", claims.franchiseeId)
			.where("caseNumber", "==", parseInt(id));
	}
};

export const getAllCases = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return db.collection("cases").orderBy("caseNumber", "desc");
	} else {
		return db
			.collection("cases")
			.where("bio.franchiseeId", "==", claims.franchiseeId)
			.orderBy("caseNumber", "desc");
	}
};

export const getCaseFiles = async (franId = "", id) => {
	//const claims = store.getters.claims;
	//if (claims.role !== "admin") {
	const files = await storage
		.ref("franchisees_files/" + franId + "/cases/" + id)
		.listAll();

	return files.items;
	// } else {
	// 	let data = await axios.get(
	// 		"https://us-central1-starjasmine-aacba.cloudfunctions.net/franAPI/getCaseFiles/" +
	// 			id,
	// 		options
	// 	);
	// 	return data.data;
	// }
};

export const getAllonHoldHelpers = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return query(
			collection(db, "cases"),
			where("caseStatus", "==", "Looking for Helper"),
			where("heldMaid", "!=", null)
		);
	} else {
		return query(
			collection(db, "cases"),
			where("caseStatus", "==", "Looking for Helper"),
			where("heldMaid", "!=", null),
			where("bio.franchiseeId", "==", claims.franchiseeId)
		);
	}
};

export const updateCase = async (caseObj) => {
	caseObj.searchResult = [];
	return await db.collection("cases").doc(caseObj.id).set(caseObj);
};

export const createInvoice = async (data) => {
	let ref = db.collection("Invoices").doc();
	return await ref.set({
		id: ref.id,
		...data,
	});
};

export const editInvoice = async (data) => {
	return await db.collection("Invoices").doc(data.id).set(data);
};

export const deleteInvoice = async (id) => {
	return await db.collection("Invoices").doc(id).delete();
};

export const geInvoiceByCaseId = async (id) => {
	const claims = store.getters.claims;
	let data;
	if (claims.role === "admin") {
		data = await db.collection("Invoices").where("caseId", "==", id);
	} else {
		data = await db
			.collection("Invoices")
			.where("franchiseeId", "==", claims.franchiseeId)
			.where("caseId", "==", id);
	}
	return data;
};

export const createNewCase = async (caseObj) => {
	const claims = store.getters.claims;
	if (claims.role !== "admin") {
		caseObj.bio.franchiseeId = claims.franchiseeId;
	}
	caseObj.searchResult = [];
	return await db.collection("cases").doc().set(caseObj);
};
export const uploadCaseFile = async (
	uploadID,
	fileName,
	baseString,
	fileType,
	franchiseeId = ""
) => {
	let data = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/franAPI/uploadCaseDocument",
		{
			uploadID: uploadID,
			fileName: fileName,
			baseString: baseString,
			fileType: fileType,
			franchiseeId: franchiseeId,
		},
		options
	);
	return data;
};
